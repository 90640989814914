<template>
  <div style="background: rgba(255, 255, 255, 1); position: relative">
    <div
      class="d-flex"
      style="width: 90%; margin: auto"
      v-if="permission.read_perm === 1"
    >
      <div style="padding: 4px 0; width: 100%; position: relative">
        <div class="d-flex justify-center align-center">
          <div style="width: 1200px">
            <v-row no-gutters>
              <v-col
                class="ecatalog-filter"
                cols="3"
                style="padding: 10px 20px 10px 0; position: relative"
              >
                <div style="position: sticky; z-index: 1; top: 60px">
                  <v-card
                    flat
                    class="catalogueCategoryBox"
                    style="
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      padding: 0 0 15px 0;
                      height: 85vh;
                      position: relative;
                      overflow: auto;
                    "
                  >
                    <div style="background: white; position: sticky; top: 0">
                      <v-card-subtitle
                        class="d-flex justify-content-start align-center"
                        tile
                        style="
                          height: 50px;
                          font-weight: 900;
                          padding: 0 10px;
                          border-radius: 5px 5px 0 0;
                          background: rgba(0, 0, 0, 0.1);
                        "
                        dense
                      >
                        Filter
                        <v-text-field
                          v-model="categorySrcValue"
                          placeholder="Cari kategori"
                          type="search"
                          outlined
                          dense
                          append-icon="mdi-magnify"
                          style="
                            height: 40px;
                            margin-left: 10px;
                            font-size: 14px;
                            font-weight: normal;
                          "
                        ></v-text-field>
                      </v-card-subtitle>
                      <v-card-subtitle
                        class="d-flex justify-content-start"
                        tile
                        style="
                          font-weight: bold;
                          height: 30px;
                          padding: 10px 15px;
                          border-radius: 0;
                        "
                        dense
                      >
                        Kategori
                      </v-card-subtitle>
                    </div>
                    <div>
                      <div
                        class="d-flex justify-content-between"
                        style="
                          margin: 10px 0 0 0;
                          padding: 0 0 0 20px;
                          font-size: 13px;
                          color: grey;
                        "
                        v-for="(item, index) in getDropdownProductCategory"
                        :key="index"
                      >
                        <span
                          class="catCategoryName"
                          :style="`cursor:pointer;${
                            item.id === productCategoryId
                              ? 'font-weight: bolder;color:indigo;'
                              : ''
                          }`"
                          @click="initCategory(item)"
                        >
                          {{ item.name }}
                        </span>
                        <span v-if="item.id === productCategoryId">
                          <v-btn
                            @click="clearCategory"
                            x-small
                            text
                            icon
                            color="red darken-4"
                          >
                            <v-icon x-small color="red darken-4"
                              >mdi-close-circle</v-icon
                            >
                          </v-btn>
                        </span>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-col>
              <v-col
                :cols="wWidth < 721 ? 12 : 9"
                class="d-flex flex-column justify-content-start align-start ecatalog-items"
              >
                <div
                  :class="`d-flex justify-content-between ${
                    wWidth < 721
                      ? 'flex-column align-start'
                      : 'flex-row align-center'
                  }`"
                  style="
                    width: 106%;
                    position: sticky;
                    top: 60px;
                    z-index: 3;
                    background: rgba(255, 255, 255, 0.9);
                    padding-bottom: 10px;
                  "
                >
                  <div style="margin: 0 30px 0 0px" class="d-flex">
                    <p
                      :style="`margin:0; color:rgba(0,0,0,0.4); ${
                        wWidth < 400 ? 'font-size:12px;' : 'font-size:14px;'
                      }`"
                    >
                      (
                      <span v-if="total_record !== 0" style="font-weight: bold">
                        {{ offset * limit - limit + 1 }} -
                        {{ arrItems.length }}
                      </span>
                      <span style="font-weight: bold" v-else> 0 - 0</span>
                      <span> dari </span>
                      <span style="font-weight: bold">{{ total_record }}</span
                      >) item
                    </p>
                  </div>
                  <div
                    cols="12"
                    style="
                      padding: 0;
                      justify-content: flex-start;
                      align-items: center;
                      display: flex;
                      margin: 5px;
                    "
                  >
                    <p style="margin: 0 5px 0 0; color: rgba(0, 0, 0, 0.5)">
                      Investasi
                    </p>
                    <v-checkbox
                      v-model="isAsset"
                      style="margin: 0; height: 25px; padding: 0"
                      @change="getAsset"
                    ></v-checkbox>
                  </div>
                  <v-select
                    v-if="wWidth < 721"
                    v-model="selectedCategory"
                    label="- Kategori produk -"
                    style="
                      margin-bottom: 5px;
                      font-size: 12px;
                      height: 40px;
                      width: 80%;
                    "
                    :items="getDropdownProductCategory"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-object
                    dense
                    @change="initCategory"
                    clearable
                  ></v-select>
                  <v-text-field
                    v-model="invokeSrc"
                    @keyup.enter="catalogueSearch"
                    dense
                    flat
                    solo
                    append-icon="mdi-magnify"
                    label="- Cari produk, merek, kode atau penyedia -"
                    single-line
                    hide-details
                    :style="`border:1px solid rgba(0,0,0,0.3); ${
                      wWidth < 721 ? 'width:80%;' : 'margin-right:75px;'
                    }`"
                  >
                  </v-text-field>
                </div>

                <div
                  v-if="arrItems.length > 0"
                  class="d-flex justify-content-start align-center flex-wrap"
                  style="width: 106%"
                >
                  <v-card
                    v-for="(item, index) in arrItems"
                    :key="index"
                    style="
                      width: 206px;
                      height: 305px;
                      margin: 8px;
                      padding: 0 3px;
                    "
                  >
                    <div v-if="item !== null">
                      <v-img
                        :src="
                          item.photo.photo_1 === null
                            ? `https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png`
                            : `${getProductImageLink}products/photo_1/${photoFilter(
                                item.photo.photo_1
                              )}`
                        "
                        aspect-ratio="1"
                        :lazy-src="`https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png`"
                        style="cursor: context-menu"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <div style="position: relative">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              elevation="1"
                              style="
                                z-index: 1;
                                left: 3px;
                                bottom: 3px;
                                font-weight: bold;
                              "
                              v-bind="attrs"
                              v-on="on"
                              absolute
                              x-small
                              fab
                              @click="checkFromOdoo(item)"
                            >
                              <v-icon
                                color="indigo"
                                style="
                                  filter: drop-shadow(2px 2px 1px #ffff8d);
                                  font-weight: bold;
                                "
                                >mdi-clipboard-text-search</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Cek Item di Odoo</span>
                        </v-tooltip>
                        <DetailImage v-bind:items="item" />
                      </div>
                      <v-card-title
                        @click="viewDetail(item)"
                        style="
                          margin-top: 3px;
                          font-size: 10px;
                          padding: 0 2px;
                          width: 100%;
                          line-height: 10px;
                        "
                      >
                        <div
                          class="d-flex justify-content-between"
                          style="width: 100%"
                        >
                          <a> #{{ item.product.code }} </a>
                          <div style="font-weight: bold">
                            ({{
                              item.purchase_uom_name !== null
                                ? item.purchase_uom_name
                                : '-'
                            }})
                          </div>
                        </div>
                      </v-card-title>
                      <v-card-title
                        style="
                          background: #bbdefbb6;
                          font-size: 12px;
                          font-weight: bold;
                          padding: 3px;
                          width: 100%;
                          line-height: 15px;
                          position: absolute;
                          top: 0;
                          left: 0;
                        "
                      >
                        {{ item.vendor.name }}
                      </v-card-title>
                      <v-tooltip bottom color="#9FA8DA">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="h2"
                            v-bind="attrs"
                            v-on="on"
                            @click="viewDetail(item)"
                            style="cursor: pointer"
                          >
                            {{ item.product.name }}
                          </div>
                        </template>
                        <span style="color: black">{{
                          item.product.name
                        }}</span>
                      </v-tooltip>

                      <!-- <div
                        style="display:flex; flex-direction:row; padding:0 2px; justify-content:space-between; align-items:flex-start; border-radius:0; border-bottom:1px solid rgba(0,0,0,0.2);"
                      >
                        <div style="font-size:12px;">Merk</div>
                        <div style="font-size:12px;font-weight:bold;">
                          {{ item.merk }}
                        </div>
                      </div> -->

                      <div
                        cols="12"
                        style="
                          display: flex;
                          flex-direction: column;
                          padding: 0 2px;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <v-col
                          cols="12"
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0;
                            margin: 0;
                          "
                        >
                          <div style="font-size: 12px">Pembelian terakhir</div>
                          <div style="font-size: 12px; font-weight: bold">
                            {{
                              item.last_ordered === null ||
                              item.last_ordered === ''
                                ? '-'
                                : dateFormat(item.last_ordered)
                            }}
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          style="
                            border-top: 1px solid rgba(0, 0, 0, 0.2);
                            padding: 0;
                          "
                        >
                        </v-col>
                        <v-col
                          cols="12"
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0;
                            margin: 0;
                          "
                        >
                          <div style="font-size: 12px">
                            Harga
                            {{
                              item.last_ordered === null ||
                              item.last_ordered === ''
                                ? 'penawaran'
                                : 'terakhir'
                            }}
                          </div>
                          <div
                            style="
                              font-size: 12px;
                              font-weight: bold;
                              display: flex;
                              flex-direction: row;
                            "
                          >
                            <p style="margin: 0; margin-right: 5px">
                              {{
                                item.currency === null
                                  ? ''
                                  : item.currency.symbol
                              }}
                            </p>
                            <p style="margin: 0">
                              <!-- v-if="!item.product.code.includes('AST')" -->
                              {{
                                item.last_price === null
                                  ? '-'
                                  : price(item.last_price)
                              }}
                            </p>
                            <!-- <p style="margin:0;" v-else>
                              -
                            </p> -->
                          </div>
                        </v-col>

                        <v-col
                          cols="12"
                          style="
                            display: flex;
                            flex-direction: row;
                            padding: 0;
                            justify-content: flex-end;
                            align-items: flex-start;
                            border-top: 1px solid rgba(0, 0, 0, 0.2);
                          "
                        >
                          <div
                            class="d-flex flex-row justify-center align-center"
                            style="
                              font-size: 12px;
                              text-align: right;
                              margin-right: 3px;
                              position: relative;
                              top: -1.5px;
                            "
                          >
                            <v-rating
                              readonly
                              :value="5"
                              background-color="white"
                              color="yellow accent-4"
                              dense
                              half-increments
                              hover
                              size="12"
                            ></v-rating>
                            <span
                              style="
                                color: rgba(0, 0, 0, 0.4);
                                font-size: 11px;
                                margin-left: 3px;
                                position: relative;
                                top: 0.5px;
                              "
                              class="text--lighten-2"
                            >
                              ({{ '5' }})
                            </span>
                          </div>
                          <v-spacer></v-spacer>
                          <div
                            style="
                              font-size: 12px;
                              color: red;
                              text-align: right;
                              margin-right: 3px;
                              position: relative;
                              top: -1.5px;
                            "
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :disabled="
                                    item.brochure === null ||
                                    item.brochure === ''
                                      ? true
                                      : false
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  icon
                                  x-small
                                  color="#9FA8DA"
                                  @click="downloadBrochure(item.brochure)"
                                >
                                  <v-icon size="16" style="cursor: pointer"
                                    >mdi-download-circle-outline</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Download brosur</span>
                            </v-tooltip>
                          </div>
                          <div
                            style="
                              font-size: 12px;
                              text-align: right;
                              margin-right: 3px;
                              position: relative;
                              top: -1.5px;
                            "
                          >
                            <v-tooltip bottom color="#9FA8DA">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  icon
                                  x-small
                                  :color="
                                    productSelection.find((a) => a === item) !==
                                    undefined
                                      ? 'black'
                                      : '#9FA8DA'
                                  "
                                  @click="selectItems(item)"
                                >
                                  <v-icon size="14" style="cursor: pointer"
                                    >mdi-compare</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span style="color: black">Bandingkan</span>
                            </v-tooltip>
                          </div>
                          <div
                            v-if="
                              getUserProfile.level.find(
                                ({ id }) => id === '41'
                              ) === undefined
                            "
                            style="
                              font-size: 12px;
                              color: red;
                              text-align: right;
                            "
                          >
                            <v-menu
                              tile
                              top
                              offset-x
                              transition="slide-x-transition"
                              :rounded="'1'"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  size="18"
                                  style="cursor: pointer; color: #9fa8da"
                                  >mdi-dots-horizontal</v-icon
                                >
                              </template>
                              <v-list style="padding: 0; width: 195px">
                                <v-list-item
                                  v-for="(tabs, i) in 1"
                                  :key="i"
                                  dense
                                  style="
                                    height: 100%;
                                    width: 100%;
                                    position: relative;
                                  "
                                >
                                  <v-tab
                                    @click="addToCart(item)"
                                    style="
                                      color: #9fa8da;
                                      text-align: center;
                                      font-size: 10px;
                                      display: flex;
                                      justify-content: center;
                                      font-weight: bold;
                                      position: absolute;
                                      top: 0;
                                      right: 0;
                                      bottom: 0;
                                      left: 0;
                                    "
                                  >
                                    Simpan ke keranjang
                                  </v-tab>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </div>
                    </div>

                    <div v-else>
                      <v-img aspect-ratio="1" style="cursor: context-menu">
                        <template v-slot:placeholder>
                          <v-sheet>
                            <v-skeleton-loader type="card"></v-skeleton-loader>
                          </v-sheet>
                        </template>
                      </v-img>
                      <div style="position: relative">
                        <v-btn
                          style="
                            z-index: 1;
                            right: 3px;
                            bottom: 3px;
                            overflow: hidden;
                          "
                          absolute
                          fab
                          x-small
                          disabled
                        >
                          <v-sheet style="background: transparent">
                            <v-skeleton-loader
                              type="avatar"
                            ></v-skeleton-loader>
                          </v-sheet>
                        </v-btn>
                      </div>
                      <v-skeleton-loader
                        style="width: 50px; margin-top: 2px"
                        type="text"
                      ></v-skeleton-loader>
                      <v-card-title
                        style="
                          padding: 3px;
                          width: 100%;
                          position: absolute;
                          top: 0;
                          left: 0;
                        "
                      >
                        <v-skeleton-loader
                          style="width: 100%; height: 20px"
                          type="image"
                        ></v-skeleton-loader>
                      </v-card-title>

                      <v-skeleton-loader
                        style="width: 100%; margin-top: -4px"
                        type="text"
                      ></v-skeleton-loader>
                      <!-- <v-skeleton-loader
                        style="width:100%;margin-top:-4px;"
                        type="text"
                      ></v-skeleton-loader> -->
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                          align-items: flex-start;
                          margin-top: -2px;
                        "
                      >
                        <v-skeleton-loader
                          style="width: 70px"
                          type="text"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          style="width: 70px"
                          type="text"
                        ></v-skeleton-loader>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                          align-items: flex-start;
                          margin-top: -2px;
                        "
                      >
                        <v-skeleton-loader
                          style="width: 70px"
                          type="text"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          style="width: 70px"
                          type="text"
                        ></v-skeleton-loader>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                          align-items: flex-start;
                          margin-top: -2px;
                        "
                      >
                        <v-skeleton-loader
                          style="width: 70px"
                          type="text"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          style="width: 70px"
                          type="text"
                        ></v-skeleton-loader>
                      </div>

                      <div
                        cols="12"
                        style="
                          display: flex;
                          flex-direction: row;
                          padding: 0;
                          justify-content: flex-end;
                          align-items: flex-start;
                        "
                      >
                        <v-skeleton-loader
                          style="width: 90px; margin-top: 5px"
                          type="text"
                        ></v-skeleton-loader>
                        <v-spacer></v-spacer>
                        <div
                          style="
                            font-size: 12px;
                            color: red;
                            text-align: right;
                            margin-right: 3px;
                            position: relative;
                            top: -1.5px;
                          "
                        >
                          <v-btn
                            disabled
                            text
                            icon
                            x-small
                            color="#9FA8DA"
                            style="overflow: hidden"
                          >
                            <v-skeleton-loader
                              type="avatar"
                            ></v-skeleton-loader>
                          </v-btn>
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            color: red;
                            text-align: right;
                            margin-right: 3px;
                            position: relative;
                            top: -1.5px;
                          "
                        >
                          <v-btn
                            disabled
                            text
                            icon
                            x-small
                            color="#9FA8DA"
                            style="overflow: hidden"
                          >
                            <v-skeleton-loader
                              type="avatar"
                            ></v-skeleton-loader>
                          </v-btn>
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            color: red;
                            text-align: right;
                            margin-right: 3px;
                            position: relative;
                            top: -1.5px;
                          "
                        >
                          <v-btn
                            disabled
                            text
                            icon
                            x-small
                            color="#9FA8DA"
                            style="overflow: hidden"
                          >
                            <v-skeleton-loader
                              type="avatar"
                            ></v-skeleton-loader>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <div
                    v-if="page < page_length"
                    style="width: 100%; height: 60px"
                    class="d-flex justify-center align-center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#e0e0e0"
                      size="50"
                    ></v-progress-circular>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-center align-center"
                  style="width: 100%; height: 25%; position: sticky; top: 25%"
                >
                  <p
                    style="
                      color: RGBA(0, 0, 0, 0.5);
                      font-size: 24px;
                      font-weight: bold;
                    "
                  >
                    #404 DATA NOT FOUND
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-card
          v-if="productSelection.length > 0"
          style="
            position: fixed;
            bottom: 0;
            left: 15px;
            right: 15px;
            z-index: 2;
          "
          align-center
          small
          dense
        >
          <v-toolbar
            elevation="0"
            style="height: 5vh"
            class="d-flex justify-content-start align-center"
          >
            <v-icon small style="margin-right: 10px">mdi-compare</v-icon>
            <v-toolbar-title style="font-size: 14px; font-weight: bold">
              KATALOG TERPILIH
            </v-toolbar-title>
            <v-badge
              small
              v-if="productSelection.length > 0"
              color="pink"
              :content="productSelection.length"
              style="margin-left: 10px"
            >
            </v-badge>
          </v-toolbar>

          <v-main
            elevation="0"
            style="text-align: center"
            class="justify-content-center align-items-center"
          >
            <p
              v-if="productSelection.length === 0"
              class="text-center"
              style="font-size: 16px; color: rgba(0, 0, 0, 0.5); margin: 0"
            >
              No product was selected
            </p>
            <v-chip
              style="margin: 0"
              v-for="(item, index) in productSelection"
              :key="index"
              class="ma-2"
              close
              @click:close="remove(index)"
            >
              {{ truncateString(item.product) }}
            </v-chip>
          </v-main>
          <v-footer
            style="border-top: 1px solid rgba(0, 0, 0, 0.15)"
            class="align-items-center justify-content-center"
          >
            <v-btn
              :disabled="productSelection.length > 1 ? false : true"
              small
              dark
              @click="compareItems"
              rounded
              outlined
              elevation="1"
              color="indigo"
              class="white--text"
              style="font-size: 12px; margin-left: 10px"
            >
              Bandingkan
            </v-btn>
            <v-btn
              :disabled="productSelection.length > 0 ? false : true"
              small
              dark
              @click="removeAllCompareItems"
              rounded
              outlined
              elevation="1"
              color="error"
              class="indigo--text"
              style="font-size: 12px; margin-left: 10px"
            >
              Batalkan
            </v-btn>
          </v-footer>
        </v-card>

        <div>
          <ProductCompare v-if="getProductCompareStat" />
        </div>
      </div>

      <div style="position: fixed; bottom: 10px; right: 0">
        <Scanner />
      </div>
    </div>

    <v-card
      flat
      height="90vh"
      v-if="permission.read_perm === 0"
      style="display: flex; justify-content: center; align-items: center"
    >
      <v-toolbar-title class="overline">
        You Can't Access This Page</v-toolbar-title
      >
    </v-card>
    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
    <v-snackbar top color="success" v-model="snackbar" :timeout="timeout">
      {{ addToCartText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="odooDialog" width="800" persistent>
      <v-card>
        <v-card-title class="text-h4 grey lighten-4">
          <div class="d-flex align-center" style="width: 100%">
            <div>
              {{ selectedItemCode !== null ? selectedItemCode : 'Odoo' }}
            </div>
            <v-btn
              @click="closeOdooDialog"
              style="margin-left: auto"
              large
              icon
            >
              <v-icon large color="red">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="d-flex justify-center align-center">
          <v-col cols="12" style="padding: 0; position: relative; top: 20px">
            <v-row v-if="odooItem !== null" no-gutters>
              <v-col cols="6" style="padding: 5px">
                <v-card style="padding: 0">
                  <v-card-title
                    style="
                      height: 40px;
                      padding: 0;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "
                    class="text-h6 primary lighten-2 d-flex justify-center align-center"
                  >
                    eSanQua
                  </v-card-title>

                  <v-card-text
                    style="
                      padding: 0;
                      margin: 0;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      border-bottom: none;
                    "
                  >
                    <p
                      :style="`color:${
                        odooItem.status === '00' ? 'green' : 'red'
                      }; font-size:14px; text-align:center; padding:5px 0 0 0; margin:0;`"
                    >
                      "{{ odooItem.message }}"
                    </p>
                  </v-card-text>
                  <v-card-text
                    style="
                      padding-top: 10px;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      border-top: none;
                    "
                  >
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0; margin-top: 10px">
                        <v-textarea
                          label="Nama Barang"
                          :value="odooItem.name"
                          dense
                          :auto-grow="true"
                          readonly
                          outlined
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" style="padding: 0; margin-top: 10px">
                        <v-text-field
                          readonly
                          dense
                          label="Code"
                          :value="odooItem.code"
                        />
                      </v-col>
                      <v-col cols="12" style="padding: 0; margin-top: 10px">
                        <v-text-field
                          readonly
                          dense
                          label="Uom"
                          :value="odooItem.uom"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" style="padding: 5px">
                <v-card style="padding: 0">
                  <v-card-title
                    style="
                      height: 40px;
                      padding: 0;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "
                    class="text-h6 purple lighten-2 d-flex justify-center align-center"
                  >
                    Odoo
                  </v-card-title>
                  <div v-if="odooItem.odoo || odooItem.odoo.length > 0">
                    <v-card-text
                      style="
                        padding: 0;
                        margin: 0;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-bottom: none;
                      "
                    >
                      <p
                        :style="`color:${
                          odooItem.status === '00' ? 'green' : 'red'
                        }; font-size:14px; text-align:center; padding:5px 0 0 0; margin:0;`"
                      >
                        "{{ odooItem.message }}"
                      </p>
                    </v-card-text>
                    <v-card-text
                      v-for="(xItems, index) in odooItem.odoo"
                      :key="index"
                      style="
                        padding-top: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-top: none;
                      "
                    >
                      <v-row no-gutters>
                        <v-col cols="12" style="padding: 0; margin-top: 10px">
                          <v-textarea
                            label="Nama Barang"
                            :value="xItems.name"
                            dense
                            :auto-grow="true"
                            outlined
                            readonly
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" style="padding: 0; margin-top: 10px">
                          <v-text-field
                            readonly
                            dense
                            label="Code"
                            :value="xItems.code"
                          />
                        </v-col>
                        <v-col cols="12" style="padding: 0; margin-top: 10px">
                          <v-text-field
                            readonly
                            dense
                            label="Uom"
                            :value="xItems.uom"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                  <v-card-text
                    v-else
                    class="d-flex justify-center align-center"
                    style="
                      padding-top: 10px;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      height: 334px;
                    "
                  >
                    <p
                      :style="`color:${
                        odooItem.status === '00' ? 'green' : 'red'
                      }; font-size:18px; font-weight:bold;`"
                      class="d-flex justify-center align-center"
                    >
                      "{{ odooItem.message }}"
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div v-else>
              <p
                class="d-flex justify-center align-center"
                style="font-weight: bold; font-size: 21px"
              >
                DATA NOT FOUND
              </p>
            </div>

            <div style="margin-top: 20px"></div>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.1);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        class="d-flex justify-center align-center"
      >
        <p
          class="d-flex justify-center align-center"
          style="font-size: 12px; margin: auto"
        >
          Please wait
        </p>
      </v-progress-circular>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../services/buildType'
import ProductCompare from '../../components/eCatalogue/catalogue_list/ProductCompare'
// import Observer from '../../components/eCatalogue/infiniteScroll/Observer'
// import Catalogue from '../../components/eCatalogue/infiniteScroll/Catalogue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DetailImage from '../../components/eCatalogue/catalogue_list/DetailImage'
import Scanner from '../home/components/Scanner.vue'
export default {
  name: 'CatalogueList',
  data: () => ({
    wWidth: window.innerWidth,
    env: process.env.VUE_APP_BUILD_TYPE,
    proc: buildType.apiURL('proc'),
    menu_key: 12,
    listAccess: false,
    permission: {},
    application: {
      id: 8,
      name: 'eCatalogue'
    },

    invokeSrc: '',
    searchCatalogue: '',
    productCategoryId: null,
    categorySrcValue: '',
    // productCategoryName: '',
    selectedCategory: null,
    drawer: false,
    productSelection: [],
    icon: 'mdi-chevron-up',

    offset: 0,
    limit: 16,
    page: 1,
    items: [],
    total_record: 0,
    page_length: -1,
    tabs: [],
    element: '0',
    arrItems: [],
    category: [],
    logT: '',
    snackbar: false,
    addToCartText: 'Berhasil ditambahkan',
    timeout: 2000,
    isAsset: false,
    selectedItemCode: null,
    odooDialog: false,
    odooItem: null,
    loading: false
  }),
  components: {
    ProductCompare,
    DetailImage,
    Scanner
    // Observer,
    // Catalogue
  },
  watch: {
    invokeSrc() {
      this.searchCatalogue = this.invokeSrc
    },
    page() {
      this.offset = this.page - 1
    },
    categorySrcValue() {
      this.dropdownProductCategory(this.categorySrcValue)
    }
  },
  computed: {
    ...mapGetters([
      'getProductCompareStat',
      'getDropdownProductCategory',
      'getUserProfile',
      'getIsLoading',
      'getParamCatList',
      'getProductImageLink'
    ])
  },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then((result) => {
            this.permission = result.data
            this.listAccess = true
            this.dropdownProductCategory()

            this.invokeSrc = this.getParamCatList.src
            this.searchCatalogue = this.getParamCatList.src
            this.productCategoryId = this.getParamCatList.id
            setTimeout(() => {
              this.setIsLoading(false)
            }, 500)
          })
          .catch((error) => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              this.listAccess = false
              setTimeout(() => {
                this.setIsLoading(false)
              }, 500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        this.listAccess = false
        setTimeout(() => {
          this.setIsLoading(false)
        }, 500)
      }
    }
    // const el = document.getElementById('catalogueContainer')
    // console.log(el.offsetHeight, el.scrollTop, window.innerHeight)
  },
  beforeMount() {
    this.getInitialData()
  },
  mounted() {
    this.setArrTabs()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    // this.getNextData()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions([
      'logout',
      'compareProduct',
      'catalogueList',
      'menu_access',
      'dropdownProductCategory',
      'brochureDownload'
    ]),
    ...mapMutations([
      'setTabs',
      'productCompare',
      'setProductCompare',
      'setIsLoading',
      'setSearchCatalogue',
      'setCatalogueDetail'
    ]),

    setArrTabs() {
      console.log('getUserLevel>>>', this.getUserProfile.level)
      const tabsArr = [
        {
          tab_name: 'Catalogue',
          path: '/e-catalogue/catalogue-list'
        },
        {
          tab_name: 'Vendor List',
          path: '/e-catalogue/vendor-list'
        },
        {
          tab_name: 'FPB',
          // path: '/e-catalogue/fpb-list'
          path: '',
          sub_tabs: [
            {
              sub_title: 'FPB List',
              path: '/e-catalogue/fpb-list'
            },
            {
              sub_title: 'Outstanding GR',
              path: '/e-catalogue/goodsreceipt/outstanding'
            }
          ],
          isShown: false
        }
      ]
      if (
        this.getUserProfile.level.find(({ id }) => id == '1' || id == '26') !==
        undefined
      ) {
        const arrTab = [
          {
            tab_name: 'RAB',
            path: '/e-catalogue/rab'
          },
          {
            tab_name: 'Project',
            path: '/e-catalogue/project'
          }
        ]
        for (let i = 0; i < arrTab.length; i++) {
          tabsArr.push(arrTab[i])
        }
      }

      if (
        this.getUserProfile.level.find(
          ({ id }) => id == '1' || id == '26' || id == '46' || id == '47'
        ) !== undefined
      ) {
        const arrTab = [
          {
            tab_name: 'Payreq',
            path: '/e-catalogue/payreq'
          },
          {
            tab_name: 'Penerimaan',
            path: '/e-catalogue/goodsreceipt'
          },
          {
            tab_name: 'PJCA',
            path: '/e-catalogue/pjca'
          }
        ]
        for (let i = 0; i < arrTab.length; i++) {
          tabsArr.push(arrTab[i])
        }
      }
      if (
        this.getUserProfile.level.find(({ id }) => id === '1') !== undefined ||
        (this.getUserProfile.level.find(({ id }) => id === '3') !== undefined &&
          this.getUserProfile.level.find(({ id }) => id === '26') !== undefined)
      ) {
        tabsArr[2].sub_tabs.push({
          sub_title: 'FPB Project',
          path: '/e-catalogue/fpb/project-item'
        })
      }

      this.tabs = tabsArr
      this.setTabs(tabsArr)
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    clearCategory() {
      document.documentElement.scrollTop = 0
      this.page = 1
      this.arrItems = []
      this.total_record = 0
      this.offset = 0
      this.limit = 16
      this.productCategoryId = null
      // this.productCategoryName = ''
      this.selectedCategory = null
      this.setSearchCatalogue([this.searchCatalogue, this.productCategoryId])
      this.getInitialData()
    },
    initCategory(item) {
      if (item !== null) {
        console.log(item)
        this.selectedCategory = item
        document.documentElement.scrollTop = 0
        this.page = 1
        this.arrItems = []
        this.total_record = 0
        // this.invokeSrc = ''
        this.offset = 0
        this.limit = 16
        this.productCategoryId = item.id
        // this.productCategoryName = item.name
        this.setSearchCatalogue([this.searchCatalogue, this.productCategoryId])
        this.getInitialData()
      } else {
        this.clearCategory()
      }
    },
    getAsset(val) {
      document.documentElement.scrollTop = 0
      this.page = 1
      this.arrItems = []
      this.total_record = 0
      this.offset = 0
      this.limit = 16
      this.setSearchCatalogue([this.searchCatalogue, this.productCategoryId])
      this.getInitialData()
    },
    async getInitialData() {
      const a = this.total_record !== 0
      const b = this.total_record - this.arrItems.length < this.limit
      const c = this.total_record - this.arrItems.length
      let d = 0
      if (a) {
        if (b) {
          d = c
        } else {
          d = this.limit
        }
      } else {
        d = this.limit
      }
      for (let i = 0; i < d; i++) {
        this.arrItems.push(null)
      }
      await this.catalogueList([
        '',
        this.searchCatalogue,
        this.offset * this.limit,
        this.limit,
        this.productCategoryId !== null ? this.productCategoryId : '',
        this.isAsset
      ])
        .then((response) => {
          console.log(response)
          if (response.status_code !== '-99') {
            this.page++
            for (let i = 0; i < response.data.length; i++) {
              // this.arrItems.push(response.data[i])
              this.arrItems[this.arrItems.length - this.limit + i] =
                response.data[i]
            }
            this.total_record = response.total_record
            this.page_length = Math.ceil(this.total_record / this.limit) || 1

            if (this.arrItems.findIndex((element) => element === null) !== -1) {
              this.arrItems.splice(
                this.arrItems.findIndex((element) => element === null)
              )
            }
          } else {
            this.arrItems = []
            this.total_record = 0
            this.page_length = 1
            document.documentElement.scrollTop = 0
            this.page = 1
            this.arrItems = []
            this.offset = 0
            this.limit = 16
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleScroll(event) {
      const valueLebih = 0.21
      const bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + valueLebih >=
          document.documentElement.offsetHeight &&
        document.documentElement.scrollTop + window.innerHeight + valueLebih <
          document.documentElement.offsetHeight + 1

      this.logT =
        document.documentElement.scrollTop +
        window.innerHeight +
        ',' +
        document.documentElement.offsetHeight +
        ',' +
        (document.documentElement.scrollTop + window.innerHeight) +
        ',' +
        (document.documentElement.offsetHeight + 1) +
        ',' +
        bottomOfWindow

      if (bottomOfWindow) {
        if (this.arrItems.length > 0) {
          if (this.page <= this.page_length) {
            // setTimeout(() => {
            this.getInitialData()
            // }, 1000)
          }
        }
      }
    },
    getNextData() {
      const valueLebih = 0.21
      window.onscroll = () => {
        const bottomOfWindow =
          document.documentElement.scrollTop +
            window.innerHeight +
            valueLebih >=
            document.documentElement.offsetHeight &&
          document.documentElement.scrollTop + window.innerHeight + valueLebih <
            document.documentElement.offsetHeight + 1

        this.logT =
          document.documentElement.scrollTop +
          window.innerHeight +
          ',' +
          document.documentElement.offsetHeight +
          ',' +
          (document.documentElement.scrollTop + window.innerHeight) +
          ',' +
          (document.documentElement.offsetHeight + 1) +
          ',' +
          bottomOfWindow

        if (bottomOfWindow) {
          if (this.arrItems.length > 0) {
            if (this.page <= this.page_length) {
              // setTimeout(() => {
              this.getInitialData()
              // }, 1000)
            }
          }
        }
      }
    },
    drawerSet(val) {
      this.drawer = val
    },
    home() {
      this.$router.push('/')
    },

    // intersected() {
    //   if (this.page !== this.page_length + 1) {
    //     for (let i = 0; i < 1; i++) {
    //       this.initCatalogueList(true)
    //     }
    //   }
    // },
    selectItems(item) {
      if (this.productSelection.includes(item)) {
        const index = this.productSelection.indexOf(item)
        this.productSelection.splice(index, 1)
      } else {
        this.productSelection.push(item)
      }
    },
    removeAllCompareItems() {
      this.productSelection = []
      // var element = document.getElementById('catalogueContainer')
      // this.toggleClass()
      // if (!element.classList.contains('normal-height')) {
      //   element.classList.remove('shorten')
      //   element.classList.add('normal-height')
      // }
    },
    remove(index) {
      this.productSelection.splice(index, 1)
      // var element = document.getElementById('catalogueContainer')
      // console.log(element.classList.contains('normal-height'))
      // if (this.productSelection.length === 0) {
      //   if (!element.classList.contains('normal-height')) {
      //     element.classList.remove('shorten')
      //     element.classList.add('normal-height')
      //   }
      // }
    },
    compareItems() {
      this.productCompare(true)
      this.compareProduct(this.productSelection)
    },
    truncateString(item) {
      const name = item.name.toLowerCase().split(' ')
      return isNaN(Number(name[0][name[0].length - 1]))
        ? name[0] + ' ' + (name[1] !== undefined ? name[1] : '')
        : name[1] + ' ' + (name[2] !== undefined ? name[2] : '')
    },
    initCatalogueList(isScroll, num) {
      // setTimeout(() => {
      //   const el = document.getElementById('catalogueContainer')
      //   el.scrollTop = 6000
      // }, 500)
      if (isScroll) {
        this.catalogueList([
          '',
          this.searchCatalogue,
          this.offset * this.limit,
          this.limit,
          this.productCategoryId !== null ? this.productCategoryId : ''
        ])
          .then((response) => {
            console.log(response)
            this.page++
            const items = response.data
            this.total_record = response.total_record
            this.page_length = Math.ceil(this.total_record / this.limit) || 1
            //  -----------------------------------------------
            if (this.items[0] !== undefined) {
              if (
                items[0].vendor.code === this.items[0].vendor.code &&
                items[0].merk === this.items[0].merk &&
                items[0].last_price === this.items[0].last_price &&
                items[0].last_ordered === this.items[0].last_ordered
              ) {
                this.initCatalogueList(true)
              } else {
                this.items = [...this.items, ...items]
              }
            } else {
              this.items = [...this.items, ...items]
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.page_length = -1
        this.page = 0
        this.items = []
        this.initCatalogueList(true, 1)
      }
    },
    catalogueSearch() {
      // this.items = []
      // this.offset = 0
      // this.setSearchCatalogue([this.searchCatalogue, this.productCategoryId])
      // this.initCatalogueList(false)
      document.documentElement.scrollTop = 0
      this.page = 1
      this.arrItems = []
      this.total_record = 0
      this.offset = 0
      this.limit = 16
      // this.productCategoryId = null
      this.setSearchCatalogue([this.searchCatalogue, this.productCategoryId])
      this.getInitialData()
    },
    // productCategoryDropdown(event) {
    //   this.productCategoryId = event
    //   this.offset = 0
    //   this.setSearchCatalogue([this.searchCatalogue, this.productCategoryId])
    //   this.initCatalogueList(false)
    // },
    // moveUp() {
    //   if (this.icon === 'mdi-chevron-up') {
    //     this.icon = 'mdi-chevron-down'
    //   } else {
    //     this.icon = 'mdi-chevron-up'
    //   }
    //   this.toggleClass()
    // },
    // toggleClass() {
    //   var element = document.getElementById('catalogueContainer')
    //   element.classList.toggle('normal-height')
    //   element.classList.toggle('shorten')
    // },

    photoFilter(link) {
      const lastIndxOf = link.lastIndexOf('/')
      const str = link.substring(lastIndxOf + 1)
      if (str === 'null') {
        return ''
      } else {
        return str
      }
    },

    dateFormat(raw) {
      const date = new Date(raw)
      return date.toLocaleDateString()
    },
    downloadBrochure(item) {
      this.brochureDownload(item)
    },

    price(val) {
      const reverse = val.toString().split('').reverse().join('')
      var ribuan = reverse.match(/\d{1,3}/g)
      return ribuan.join(',').split('').reverse().join('')
    },

    async addToCart(item) {
      let xFlag = false
      this.loading = true
      const itemArr = []
      if (item.product.code !== '') {
        const xItem = {
          code: item.product.code,
          name: item.product.name,
          uom: item.uom_name,
          index: 0
        }

        itemArr.push(xItem)
        await axios
          .post(`${this.proc}fpb/odoo/check_item`, { items: itemArr })
          .then(async (res) => {
            this.loading = false
            if (res.data.data[0].status === -99) {
              await this.showMsgDialog(
                'warning',
                '<span style="font-size:12px;color:red;">' +
                  res.data.data[0].code +
                  ' => ' +
                  res.data.data[0].message +
                  '</span>' +
                  '<br/>' +
                  'Barang ini terdeteksi tidak sesuai dengan data di odoo, apakah Anda tetap ingin melanjutkan ?',
                true,
                'Masukkan ke keranjang',
                'Batalkan',
                '<span style="font-size:14px;">' +
                  'Hiraukan bila digunakan untuk FPB project atau FPB yang semua itemnya akan dibuatkan CA' +
                  '</span>' +
                  '<br/>' +
                  'atau hubungi admin agar dapat dilakukan penyesuaian dahulu !'
              ).then((res) => {
                if (res.isConfirmed) {
                  xFlag = true
                } else {
                  xFlag = false
                }
              })
            } else {
              xFlag = true
            }
            return null
          })
          .catch((err) => {
            console.log(err)
            xFlag = false
            this.loading = false
            return null
          })
        // const xFlag = true
        // // if (
        // //   this.$store.state.cart.savedData.find(
        // //     a =>
        // //       a.product === item.product &&
        // //       a.vendor.clear_id === item.vendor.clear_id
        // //   ) === undefined
        // // ) {
        console.log('hereeee >>>>>>')
        if (xFlag) {
          this.$store.commit('setCartList', item)
          this.snackbar = true
        }
        // // }
      } else {
        this.loading = false
        this.showMsgDialog(
          'warning',
          'Barang ini tidak dapat digunakan karena tidak memiliki kode, silahkan menghubungi Admin untuk info lebih lanjut',
          false
        )
      }
    },

    viewDetail(data) {
      this.setCatalogueDetail(data)
      this.$router.push('/e-catalogue/catalogue-detail')
      // this.$router.push({
      //   name: 'catalogueDetail',
      //   hash: '#catalogue'
      // })
    },
    checkFromOdoo(item) {
      this.loading = true
      const itemArr = []

      const xItem = {
        code: item.product.code,
        name: item.product.name,
        uom: item.uom_name,
        index: 0
      }

      itemArr.push(xItem)
      axios
        .post(`${this.proc}fpb/odoo/check_item`, { items: itemArr })
        .then((res) => {
          this.selectedItemCode = item.product.code
          this.odooItem = res.data.data[0]
          setTimeout(() => {
            this.odooDialog = true
            this.loading = false
          }, 200)
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog('error', `${err}`, false)
        })
    },
    closeOdooDialog() {
      this.loading = false
      this.odooDialog = false
      this.odooItem = null
      this.selectedItemCode = null
    },

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, yesBtn, noBtn, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: yesBtn,
            cancelButtonText: noBtn !== '' ? noBtn : 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    }
  }
}
</script>

<style lang="scss">
#catalogueContainer {
  transition: 0.3s;
}
.shorten {
  height: 55vh;
}
.normal-height {
  height: 84vh;
}

$font-size: 14px;
$line-height: 1.2;
$lines-to-show: 2;
.h2 {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: $font-size * $line-height * $lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(17, 0, 255);
  text-align: left;
  // color: blue;
  // line-height: 18px;
  // padding:0;
  // width:100%;
  // cursor:default;
  padding: 0 2px;
  // height:40px;
}
.catCategoryName {
  &:hover {
    color: indigo;
  }
}
// .catalogueCategoryBox {
// height: 87vh;
// overflow-y: scroll;
// &::-webkit-scrollbar {
//   display: none;
// }
// }

@media (max-width: 722px) {
  .ecatalog-filter {
    display: none;
  }
}
</style>
